import React from 'react'
import { withRouter } from 'react-router-dom'
import './Footer.scss'
import { routerTreePathConfig } from '@/routeTree'
import { IMG_ORIGINAL } from '@utils/constant'

class ProductApply extends React.PureComponent {
  onApply = () => {
    this.props.history.push(`${routerTreePathConfig.FreeTrial}?key=${this.props.applyItem.key}&tab=${this.props.applyItem.tab}`)
  }

  render() {
    return (
      <div className='product-apply'>
        <div className='apply-bg'>
          <img src={`${IMG_ORIGINAL}/product_apply_bg.png`} alt='' />
        </div>
        <div className='apply-content'>
          <div className='title'>{this.props.applyItem.text}</div>
          <div className='apply-btn' onClick={this.onApply}>
            申请试用
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(ProductApply)
