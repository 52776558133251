import React, { useState } from 'react'
// import {getArtical} from '@api/artical.js'
import { renderRoutes } from 'react-router-config'
import {dynamicEleFontSize} from '@utils'
import Header from "./Container/Header";
import Footer from "./Container/Footer";
import './App.scss'
import './Styles/aek.scss'

function App(props) {
  // getArtical({
  //   channelRequestType: "backend",
  //   menuCategory: 2,
  //   systemCategory: 13}).then((res)=>console.log(res))
  dynamicEleFontSize(document, window)
  const [state, setState] = useState({
      actionFlag: false
  })

    // 回到顶部监听
  window.onscroll = function () {
        const clientHeight = document.documentElement.clientHeight || document.body.clientHeight
        const scrollY = window.scrollY
        setState({...state, actionFlag: scrollY > clientHeight})
    }
  return (
    <div className='App g-appRoot'>
      <Header appProps={props}></Header>
      <div className='content'>{renderRoutes(props.route.routes)}</div>
      <Footer appProps={props}></Footer>
      {state.actionFlag && (<div className="goTop" onClick={()=>{window.scrollTo(0,0)}}><img src='//img.ebei99.com/official-website/g-scroll-top.png' alt=""/></div>  )}
    </div>
  )
}

export default App
