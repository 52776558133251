export const routerTreePathConfig = {
  // 配置path路径
  Home: '/',
  ShopsSearch: '/about', // 整站搜相关店铺
  FreeTrial: '/freeTrial', // 免费试用
  ApplySuccess: '/applySuccess', // 申请成功
  StaffHired: '/staffHired', // 招聘
  News: '/news', // 招聘
  UserScenarios: '/userScenarios', // 用户案例
  NewsDetail: '/news-detail', // 招聘
  About: '/about', // 关于我们
  CRM: '/crm', // crm-客户关系管理系统
  MSC: '/msc', // 医共体平台
  SCM: '/scm', // 数字化营销管理系统
  RFID: '/rfid', // RFID-溯源系统备份
  SPD: '/spd', // SPD-物流管理系统
  ERP: '/erp', // ERP-进销存管理系统
  SCC: '/scc', // 供应链协同系统
  Materials: '/materials', // 物资精细化管理系统
}
export default [
  { path: routerTreePathConfig.Home, exact: true, filePath: '/home' }, // 首页
  { path: routerTreePathConfig.ShopsSearch, exact: true, filePath: '/about' }, // 搜索店铺页面
  { path: routerTreePathConfig.FreeTrial, exact: true, filePath: routerTreePathConfig.FreeTrial }, // 免费试用
  { path: routerTreePathConfig.ApplySuccess, exact: true, filePath: routerTreePathConfig.ApplySuccess }, // 申请成功
  { path: routerTreePathConfig.StaffHired, exact: true, filePath: '/staffHired' }, // 招聘
  { path: routerTreePathConfig.News, exact: true, filePath: '/news' }, // 新闻
  { path: routerTreePathConfig.UserScenarios, exact: true, filePath: '/userScenarios' }, // 用户案例
  { path: routerTreePathConfig.NewsDetail, exact: true, filePath: '/newsDetail' }, // 新闻详情
  { path: routerTreePathConfig.About, exact: true, filePath: '/about' }, // 关于我们
  { path: routerTreePathConfig.CRM, exact: true, filePath: '/product/crm', productApply: true }, // crm-客户关系管理系统
  { path: routerTreePathConfig.MSC, exact: true, filePath: '/product/msc', productApply: true }, // 医共体平台
  { path: routerTreePathConfig.SCM, exact: true, filePath: '/product/scm', productApply: true }, // 数字化营销管理系统
  { path: routerTreePathConfig.RFID, exact: true, filePath: '/product/rfid', productApply: true }, // RFID-溯源系统备份
  { path: routerTreePathConfig.SPD, exact: true, filePath: '/product/spd', productApply: true }, // SPD-物流管理系统
  { path: routerTreePathConfig.ERP, exact: true, filePath: '/product/erp', productApply: true }, // ERP-进销存管理系统
  { path: routerTreePathConfig.SCC, exact: true, filePath: '/product/scc', productApply: true }, // 供应链协同系统
  { path: routerTreePathConfig.Materials, exact: true, filePath: '/product/materials', productApply: true }, // 物资精细化管理系统
]
