import React from 'react'
import './Header.scss'
import { Link } from 'react-router-dom'
import { routerTreePathConfig } from '@/routeTree'
import { Modal, Button } from 'antd'
import { IMG_ORIGINAL, SALE_HOTLINE } from '@utils/constant'

class Header extends React.Component {
  state = {
    selectedKeys: '/',
    visible: false,
    isShowProductList: false,
  }

  domHeight = 0

  onGoproduct(path) {
    const { history } = this.props.appProps
    history.push(path)
    location.reload()
  }

  render() {
    const {
      location: { pathname },
      history,
    } = this.props.appProps
    const productPathArr = ['/crm', '/msc', '/scm', '/rfid', '/spd', '/erp', '/scc', '/materials']
    return (
      <div className='header'>
        <div className='header-content'>
          <div
            className='logo-title'
            onClick={() => {
              history.push(routerTreePathConfig.Home)
            }}>
            <img src={`${IMG_ORIGINAL}/ayklogo.svg`} alt='' />
          </div>
          <div className='header-nav'>
            <Link style={{whitespace: 'nowrap'}} to={routerTreePathConfig.Home} className={pathname === routerTreePathConfig.Home ? 'link link-active' : 'link'}>
              官网首页
            </Link>
            <Link
                style={{whitespace: 'nowrap'}}
              to={routerTreePathConfig.SPD}
              className={productPathArr.includes(pathname) ? 'link link-active product-link' : 'link product-link'}
              onMouseMove={() => {
                this.setState({ isShowProductList: true })
              }}>
              产品服务<i className={productPathArr.includes(pathname) ? 'arrow arrow-active' : 'arrow'} />
            </Link>
            <Link style={{whitespace: 'nowrap'}} to={routerTreePathConfig.UserScenarios} className={pathname === routerTreePathConfig.UserScenarios ? 'link link-active' : 'link'}>
              用户案例
            </Link>
            <Link
                style={{whitespace: 'nowrap'}}
              to={routerTreePathConfig.News}
              className={[routerTreePathConfig.News, routerTreePathConfig.NewsDetail].includes(pathname) ? 'link link-active' : 'link'}>
              新闻中心
            </Link>
            <Link style={{whitespace: 'nowrap'}} to={routerTreePathConfig.About} className={pathname === routerTreePathConfig.About ? 'link link-active' : 'link'}>
              关于我们
            </Link>
            <Link style={{whitespace: 'nowrap'}} to={routerTreePathConfig.StaffHired} className={pathname === routerTreePathConfig.StaffHired ? 'link link-active' : 'link'}>
              人才招聘
            </Link>
          </div>
          <div className='contacts'>
            <div className='phone'>销售热线：{SALE_HOTLINE}</div>
            <div className='action-jump'>
              <div
                className='free button'
                onClick={() => {
                  history.push(routerTreePathConfig.FreeTrial)
                }}>
                <span>免费试用</span>
              </div>
              <div
                className='login button'
                onClick={() => {
                  this.setState({ visible: true })
                }}>
                <span>立即登录</span>
              </div>
            </div>
          </div>
        </div>
        {this.state.isShowProductList && (
          <div ref='productNav' className='product-nav' onMouseLeave={() => this.setState({ isShowProductList: false })}>
            <div className='product-content'>
              <div className='left'>
                <div className='title'>医疗机构供应链解决方案</div>
                <div className='left-nav'>
                  <div
                    className='nav-item'
                    onClick={() => {
                      this.onGoproduct('scc')
                    }}>
                    <img src={`${IMG_ORIGINAL}/nav1.png`} alt='' />
                    <div className='nav-title'>供应链协同系统</div>
                  </div>
                  <div
                    className='nav-item'
                    onClick={() => {
                      this.onGoproduct('msc')
                    }}>
                    <img src={`${IMG_ORIGINAL}/nav2.png`} alt='' />
                    <div className='nav-title'>医共体平台</div>
                  </div>
                  <div
                    className='nav-item'
                    onClick={() => {
                      this.onGoproduct('spd')
                    }}>
                    <img src={`${IMG_ORIGINAL}/nav3.png`} alt='' />
                    <div className='nav-title'>SPD管理系统</div>
                  </div>
                  <div
                    className='nav-item'
                    onClick={() => {
                      this.onGoproduct('materials')
                    }}>
                    <img src={`${IMG_ORIGINAL}/nav4.png`} alt='' />
                    <div className='nav-title'>物资精细化管理</div>
                  </div>
                </div>
              </div>
              <div className='split'></div>
              <div className='right'>
                <div className='title'>医疗企业解决方案</div>
                <div className='left-nav'>
                  <div
                    className='nav-item'
                    onClick={() => {
                      this.onGoproduct('crm')
                    }}>
                    <img src={`${IMG_ORIGINAL}/nav5.png`} alt='' />
                    <div className='nav-title'>CRM客户关系管理</div>
                  </div>
                  <div
                    className='nav-item'
                    onClick={() => {
                      this.onGoproduct('erp')
                    }}>
                    <img src={`${IMG_ORIGINAL}/nav6.png`} alt='' />
                    <div className='nav-title'>医疗耗材ERP</div>
                  </div>
                  <div
                    className='nav-item'
                    onClick={() => {
                      this.onGoproduct('rfid')
                    }}>
                    <img src={`${IMG_ORIGINAL}/nav7.png`} alt='' />
                    <div className='nav-title'>RFID溯源</div>
                  </div>
                  <div
                    className='nav-item'
                    onClick={() => {
                      this.onGoproduct('scm')
                    }}>
                    <img src={`${IMG_ORIGINAL}/nav8.png`} alt='' />
                    <div className='nav-title'>数字化营销</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Modal
          title='请选择登陆角色'
          width='500px'
          mask
          keyboard
          maskClosable
          footer={null}
          onCancel={() => {
            this.setState({ visible: false })
          }}
          visible={this.state.visible}>
          <div className='ui-loginTypes-box'>
            <a href='//medical.aek56.com' target='_blank' className='loginTypes-item loginTypes-item-hospital'>
              <img src='//img.ebei99.com/ebei99-web/org-h.png' />
              我是医疗机构
            </a>
            <a href='//lobby.aek56.com' target='_blank' className='loginTypes-item loginTypes-item-hospital'>
              <img src='//img.ebei99.com/ebei99-web/org-b.png' />
              我是企业
            </a>
          </div>
        </Modal>
      </div>
    )
  }
}

export default Header
