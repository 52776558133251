import React from 'react'
import PropTypes from 'prop-types'
import './Footer.scss'
import { routerTreePathConfig } from '@/routeTree'

export default class Animation extends React.PureComponent {
  render() {
    return (
      <div className='animation-con'>
        <img src='//img.ebei99.com/official-website/footer-wave.png' alt='' />
        <div className='footer-img-hover aek-vertical-left'>
          <img src='//img.ebei99.com/official-website/footer-wave-hover.png' alt='' />
          <img src='//img.ebei99.com/official-website/footer-wave-hover.png' alt='' />
          <img src='//img.ebei99.com/official-website/footer-wave-hover.png' alt='' />
        </div>
        <div className='middle-con'>
          <p className='slogan'>聚焦客户挑战与需求，提供有竞争力的产品和服务</p>
          <div className='btn-con'>
            <div className='left-btn' onClick={() => this.props.history.push(routerTreePathConfig.FreeTrial)}>
              免费试用
            </div>
            <div className='right-btn' onClick={() => this.props.history.push(routerTreePathConfig.FreeTrial)}>
              预约演示
            </div>
          </div>
        </div>
      </div>
    )
  }
}
