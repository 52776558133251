import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, withRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import Loadable from 'react-loadable'
import routeTree from './routeTree'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// routes
const Loading = (props) => {
  if (props.error || props.timedOut) {
    console && console.error('props.error || props.timedOut', props.error || props.timedOut)
    // window.location.reload();
  }
  return <div className="ui-loading" />
}
const routeList = []

routeTree.forEach((item) => {
  const PageComponent = Loadable({
    loader: () => import(`/pages${item.filePath}`),
    loading: Loading,
  })
  routeList.push(Object.assign({ component: PageComponent }, item))
})

const routes = [
  {
    component: App,
    routes: routeList,
  },
]

// scrollTop
class ScrollTop extends React.Component {
  componentDidUpdate(nextProps) {
    const { location } = this.props
    if (location !== nextProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const { children } = this.props
    return children
  }
}

const WithRot = withRouter(ScrollTop)
ReactDOM.render(
      <Router>
        <WithRot>
          {renderRoutes(routes)}
        </WithRot>
      </Router>,
  document.getElementById('root'),
)

serviceWorker.unregister();
