import { isEmpty, inRange } from 'lodash'
import { message } from 'antd'
import { CONSUMER_HOTLINE, SALE_HOTLINE } from '@utils/constant'
import request from './request'

const REQUEST_SUCCESS_REGION = [199, 401]

// const openNotification = ({ type, message }) => {
//   notification[type]({
//     message: '错误',
//     description: message || `系统异常, 请联系客服: ${CONSUMER_HOTLINE}`,
//     duration: 5,
//   })
// }

const refineJsonData = (json) => {
  Object.entries(json).forEach(([key, value]) => {
    if (value) {
      if (value.constructor && value.constructor === Object) {
        json[key] = refineJsonData(value)
      } else if (typeof value === 'string' && value.includes('{')) {
        try {
          json[key] = JSON.parse(value)
        } catch (e) {
          console.log('refineJsonData error', e)
        }
      }
    } else if (value === null) {
      delete json[key]
    }
  })
  return json
}

/**
 * 预处理返回的数据
 * @param forceParseJson
 * @returns {function(...[*]=)}
 */
const resolveResponse = (response) => {
  const { data, status } = response
  if (status !== 200) {
    return message.error(`系统异常, 请联系客服: ${CONSUMER_HOTLINE}`)
  }
  const { code, message: msg } = data
  if (inRange(code, ...REQUEST_SUCCESS_REGION)) {
    return refineJsonData(data)
  }
  message.error(msg || `系统异常, 请联系客服: ${CONSUMER_HOTLINE}`)
  throw response
}

export const fetchApi = (url, method = 'post') => async (data = {}) => {
  const res = await request({ url, method, data, headers: { 'aek-system-code': 10 } })
  return resolveResponse(res)
}

const dynamicEleFontSize = (doc, win) => {
  const html = document.documentElement // 获取html元素
  // debugger
  // const event = 'onorientationchange' in window ? 'orientationchange' : 'resize' // 判断是屏幕旋转还是resize;
  // const fn = function() {
  const width = html.clientWidth
  if (width < 1500) {
    html.style.fontSize = `${100 * (width / 1440)}px` // 设置html的fontSize，随着event的改变而改变。
  } else {
    html.style.fontSize = `${100 * (width / 1920)}px`
  }
  // }

  // win.addEventListener(event, fn, false)
  // doc.addEventListener('DOMContentLoaded', fn, false)
}

/**
 * url参数获取
 * @param   {String}
 * @return  {String}
 */
const getUrlParam = (name, search) => {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i')
  const r = search ? search.substr(1).match(reg) : window.location.search.substr(1).match(reg)
  if (r != null) return decodeURIComponent(r[2])
  return null
}

export { dynamicEleFontSize, getUrlParam }

export default fetchApi
